import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { Carousel } from "flowbite-react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import "../styles/buttons.css";
import { useNavbarVisibility } from "../components/NavbarVisibilityContext";
import { useFooterVisibility } from "../components/FooterVisibilityContext";
import { FaArrowRightLong } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from 'react-helmet-async';

export default function HomePage() {
  const { currentUser } = useSelector((state) => state.user);

  const { setIsNavbarVisible } = useNavbarVisibility();
  const { setIsFooterVisible } = useFooterVisibility();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const navigate = useNavigate();
  const gsapContainer = useRef();

  const testimonialData = [
    {
        name: 'Shivani Merchant',
        designation: "",
        company: "",
        image: "https://firebasestorage.googleapis.com/v0/b/benda-v1.firebasestorage.app/o/images%2Fshivani.jpeg?alt=media&token=057238e5-9df7-4420-8881-5cbbab8d45dd",
        comment: "Working with Benda Infotech has transformed my job search. Their expert guidance and support, especially from Alina, boosted my confidence and helped me identify ideal career opportunities. A true career partner."
    },
    {
        name: 'Kwabena Duku',
        designation: '',
        company: '',
        image: "https://firebasestorage.googleapis.com/v0/b/benda-v1.firebasestorage.app/o/images%2Fduku.jpeg?alt=media&token=caca5753-83b8-4300-a863-74bd82288b9b",
        comment: 'Working with Benda Infotech boosted my technical skills, enhanced productivity, and provided valuable project experience. Their expert guidance, client-focused solutions, and support made the collaboration highly rewarding. Highly recommended.'
    },
    {
        name: 'Anupriya Nandakumar',
        designation: '',
        company: '',
        image: "https://firebasestorage.googleapis.com/v0/b/benda-v1.firebasestorage.app/o/images%2Favatar-woman.png?alt=media&token=1dda8b73-0de4-4c17-b15d-aee3b68fc299",
        comment: 'The service team, especially Alina, provided prompt and helpful support. However, communication from the training team and improvements to the study materials and testing platform are needed for a better experience.'
    },
    {
        name: 'Collins Ofori',
        designation: '',
        company: '',
        image: "https://firebasestorage.googleapis.com/v0/b/benda-v1.firebasestorage.app/o/images%2Favatar-men.jpg?alt=media&token=def58af8-a523-4c84-a3ec-50716e3ea273",
        comment: 'Benda Infotech exceeded my expectations for professional development. Their expertise significantly boosted my technical skills, interview preparation, and project assessment abilities. Their support was invaluable. Thank you for the opportunity. I look forward to future work together.'
    },
    {
        name: 'Ebenezer Asiedu',
        designation: '',
        company: '',
        image: "https://firebasestorage.googleapis.com/v0/b/benda-v1.firebasestorage.app/o/images%2FEbenezer.jpeg?alt=media&token=15ce48c7-05f9-4dd0-b955-f024744daaba",
        comment: 'Working with Benda Infotech has been a an exciting insightful journey so far. Providing the requisite training and tools needed to explore real world data based on analysis and also assisting with student OPT extension. I’m very excited to be part of this organization.'
    }

]

  const sidesData = [
    {
      questionf: "What h-or-g.com does?",
      deatils:
        "We provide a skills assessment platform that evaluates candidates across industries, ensuring the right fit for any role. Our tools streamline hiring, making it faster and more efficient for businesses to find top talent.",
      bgImg: "",
      img: "./images/faq/faq-1.webp",
    },
    {
      questionf: "What types of skill assessments do you offer?",
      deatils:
        "At H-OR-G, we offer a wide range of skill assessments tailored to various roles and industries.",
      bgImg: "",
      img: "./images/faq/faq-2.webp",
    },
    {
      questionf: "Are the assessments recognized by employers?",
      deatils:
        "Yes, our assessments are designed to meet industry standards and are widely recognized by employers, helping you stand out during the hiring process.",
      bgImg: "",
      img: "./images/faq/faq-3.webp",
    },
    {
      questionf: "How soon will I know my results after the assessment?",
      deatils:
        "You will receive your results immediately after the assessment in the form of a downloadable PDF report.",
      bgImg: "",
      img: "./images/faq/faq-4.webp",
    },
    {
      questionf: "Do you offer interview preparation or coaching?",
      deatils:
        "Yes, h-or-g.com provides interview preparation and coaching services to help candidates improve their skills, boost confidence, and succeed in job interviews.",
      bgImg: "",
      img: "./images/faq/faq-5.webp",
    },
  ];

  useEffect(() => {
    setIsFooterVisible(true);
    setIsNavbarVisible(true);
  }, []);

  useEffect(() => {
    // Register GSAP ScrollTrigger
    gsap.registerPlugin(ScrollTrigger);

    // Select all elements with the 'about-us' class
    const homeSections = document.querySelectorAll(".home-animation");

    homeSections.forEach((section) => {
      gsap.fromTo(
        section, // Target each section
        { opacity: 0, y: 30 }, // Initial state
        {
          opacity: 1,
          y: 0,
          duration: 1.4,
          ease: "power3.out",
          scrollTrigger: {
            trigger: section, // Trigger animation when the section enters the viewport
            start: "top 92%", // Animation starts when the top of the section is 80% into the viewport
            toggleActions: "play none none none", // Play animation on scroll
            once: true, // Run animation only once
          },
        }
      );
    });

    return () => {
      // Clean up ScrollTrigger instances on component unmount
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  //----------------------------------------- Handle Get Started ---------------------------------------
  const handleGetStarted = () => {
    if (currentUser && !currentUser.isRecruiter) {
      navigate("/selectSkill");
    } else if (currentUser && currentUser.isRecruiter) {
      navigate("/viewPlans");
    } else {
      navigate("/signup");
    }
  };

  //Handle switching to the selectSkill page
  const handleTakeTest = () => {
    navigate("/selectSkill");
  };

  return (
    <>
      <Helmet>
        <title>Free Online Skill Tests | Coding & General Skills Assessment Made Easy</title>
        <meta
          name="description"
          content="Enhance your career with our free online skill tests. Test your abilities with coding skill tests, general skill assessments, and more—all available for free online. Start now!"
        />
      </Helmet>
      <div
        ref={gsapContainer}
        className="flex flex-col items-center w-full overflow-y-auto top-0 -mt-20"
      >
        {/* HERO section */}
        <div className="hero-section w-full  flex justify-center h-[1020px] bg-blue-800  overflow-x-hidden overflow-y-auto">
          <img
            src="./images/hero-bg.svg"
            alt="elemental-Design"
            className="absolute h-auto w-full left-0 -ml-6 opacity-15 width-container mx-auto overflow-y-hidden object-cover"
          />
          <div className="absolute md:w-[1080px] flex flex-col items-center mt-[145px] width-container ">
            {/* <p className="text-white text-center text-xs my-4 opacity-65">TalentEvaluation <b>|</b> JobReadiness <b>|</b> PlacementAssurance</p> */}
            <p className="text-white text-center font-josefinSans w-4/5 text-xl opacity-50 my-4 md:w-[650px] home-animation">
              Discover top talent faster, reduce costs, and make
              <br />
              smarter hiring decisions. Hire or Get-hired now.
            </p>
            <h1 className="font-semibold font-josefinSans text-lg md:text-[48px]  text-center md:w-[650px] text-white mt-6 home-animation">
              {" "}
              We simplify hiring with AI,
              <br />
              <br />
              helping businesses find the
              <br />
              <br />
              perfect talent.
            </h1>
            <p className="text-center"></p>

            <div className="mt-10 flex w-2/3 justify-center gap-2 md:gap-10 home-animation">
              <button
                className="s_btn uppercase font-josefinSans"
                onClick={handleGetStarted}
              >
                Get Started
              </button>
            </div>

            <img
              className="w-[1100px] relative rounded-lg mb-10 home-animation"
              src="./images/hero-img1.webp"
              alt="img-1"
            />
          </div>
        </div>

        {/* STEPS */}
        <div className="flex flex-row items-center gap-2 h-auto w-full width-container mt-6 md:mt-60 home-animation">
          <div className="w-72 h-48 rotate-[-25deg]">
            <div className="flex flex-col items-center gap-4">
              <p className="text-sm text-blue-800 text-center md:w-56">
                Streamline your hiring process or boost your job search -<br />
                See how it works!
              </p>
              <a href="/signup">
                <img
                  className="w-14 transform transition-transform duration-300 ease-in-out hover:scale-125"
                  src="./images/arrow.svg"
                  alt="arrow-img"
                />
              </a>
            </div>
          </div>

          <div className="w-72 h-48 ">
            <div className="flex flex-row items-center gap-4">
              <div className=" border-2 border-blue-900 rounded-full h-10 w-10 text-center flex justify-center items-center">
                <p className="text-2xl text-blue-900 p-3">1</p>
              </div>
              <h1 className="text-lg text-blue-900 font-semibold">
                Sign Up or Sign In
              </h1>
            </div>
            <div className="mt-4 ml-8">
              <p className="text-sm text-gray-600">
                Create your account by filling in basic information or log in
                quickly using Google.
              </p>
            </div>
          </div>

          <div className="w-72 h-48 mx-6">
            <div className="flex flex-row items-center gap-4">
              <div className=" border-2 border-blue-900 rounded-full h-10 w-10 text-center flex justify-center items-center">
                <p className="text-2xl text-blue-900 p-3">2</p>
              </div>
              <h1 className="text-lg text-blue-900 font-semibold">
                Complete Your Profile
              </h1>
            </div>
            <div className="mt-4 ml-8">
              <p className="text-sm text-gray-600">
                Tell us a bit more about yourself. If you’re a recruiter, we’ll
                complete an additional verification for secure access.
              </p>
            </div>
          </div>

          <div className="w-72 h-48 ">
            <div className="flex flex-row items-center gap-4">
              <div className=" border-2 border-blue-900 rounded-full h-10 w-10 text-center flex justify-center items-center">
                <p className="text-2xl text-blue-900 p-3">3</p>
              </div>
              <h1 className="text-lg text-blue-900 font-semibold">
                Start Using Dashboard
              </h1>
            </div>
            <div className="mt-4 ml-8">
              <p className="text-sm text-gray-600">
                Access all features right away! Whether sending skill tests or
                finding job opportunities, start exploring now.
              </p>
            </div>
          </div>

          {/* <div className="mb-10">
                                    <button class="relative text-sky-600 font-semibold px-4 py-2 overflow-hidden group">
                                        Start Journey
                                        <HiArrowNarrowRight className="inline-block" size={20} />
                                        <span class="absolute bottom-0 left-0 w-full h-0.5 bg-sky-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700 ease-in-out origin-left"></span>
                                    </button>
                                </div> */}
        </div>

        {/* TestiMonials */}
        <div class="relative w-full h-auto max-w-[1040px] my-14 home-animation">
          <div class="w-full h-64 bg-blue-50 absolute inset-0 rounded-lg -translate-x-6 translate-y-6 shadow-md"></div>
          <div class="absolute inset-0 w-full h-64 bg-blue-200 rounded-lg -translate-x-3 translate-y-3 shadow-md"></div>
          <div class="relative w-full h-64 bg-blue-700 text-white p-8 rounded-lg shadow-lg">
            <Carousel
              pauseOnHover
              indicators={false}
              leftControl={
                <IoIosArrowDropleft className="h-8 w-8 text-gray-300 opacity-25" />
              }
              rightControl={
                <IoIosArrowDropright className="h-8 w-8 text-gray-300 opacity-25" />
              }
            >
              {testimonialData.map((testimonial, index) => (
                <div
                  key={index}
                  className="h-full w-full px-8 flex justify-center items-center"
                >
                  <div className="mt-6 mx-40 mb-6 h-full w-full flex flex-row items-center justify-center">
                    <img
                      className="w-[40px] h-[40px]  mr-4 mb-[74px] rounded-full  border-2 border-gray-200 shadow-lg shadow-gray-800 object-cover"
                      src={testimonial.image}
                      alt="..."
                    />

                    <div className="my-5 inline-block text-left">
                      <p className="font-semibold text-base mt-3  text-white text-left">
                        {testimonial.name}
                        <br />
                      </p>
                      {(testimonial.designation || testimonial.company) && (
                        <p className="text-gray-200 text-[10px] text-center border-t">
                          {testimonial.designation && testimonial.designation}{" "}
                          {testimonial.company && testimonial.company}
                        </p>
                      )}
                      <span className="inline-block align-middle">
                        <ImQuotesLeft
                          size={30}
                          className="mr-1 text-gray-400"
                        />
                      </span>

                      <p className="inline text-gray-200 text-xs text-left align-middle">
                        {testimonial.comment}
                      </p>
                      <span className="inline-block align-middle">
                        <ImQuotesRight
                          size={30}
                          className="ml-1 text-gray-400"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        {/* CARD SECTION */}
        <div className="w-full h-auto flex flex-col items-center justify-center mb-14 mt-14">
          {/* CARD 1 */}
          <div className="w-full h-auto max-w-[1140px] p-4 flex flex-row items-center home-animation">
            {/* photo section */}
            <div>
              <div className="w-[500px] h-[400px] absolute bg-sky-100 rounded-lg shadow-lg"></div>
              <div className="w-[460px] h-[350px] absolute bg-sky-200 translate-x-12 translate-y-6 rounded-lg shadow-lg"></div>
              <div
                className="w-[460px] h-[350px] relative bg-sky-800 translate-x-10 translate-y-4 rounded-lg shadow-lg"
                style={{
                  backgroundImage: "url(./images/card-1.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            {/* Text Section */}
            <div className="ml-32">
              <p className="text-lg text-gray-500">AI & Human Expertise</p>
              <h2 className="my-2 text-gray-700 font-semibold text-2xl">
                Streamline Your Hiring Process
              </h2>
              <p className="text-gray-800 text-sm my-3">
                Find top candidates faster with AI-powered resume matching.
                Assess technical skills before interviews with customized skill
                tests. Access a curated selection of exclusive resumes,
                pre-screened by our recruiting experts.
              </p>
              <button
                onClick={handleTakeTest}
                className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
              >
                Explore more
                <FaArrowRightLong
                  size={20}
                  className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                />
              </button>
            </div>
          </div>

          {/* CARD 2 */}
          <div className="h-auto max-w-[1140px] p-4 flex flex-row-reverse items-center mt-12 home-animation">
            {/* photo section */}
            <div className="ml-24 mr-32 mb-52">
              <div className="w-[500px] h-[400px] absolute bg-teal-100 rounded-lg shadow-md"></div>
              <div className="w-[380px] h-[300px] absolute bg-teal-200 translate-x-12 translate-y-6 rounded-lg shadow-md"></div>
              <div
                className="w-[380px] h-[300px] absolute bg-sky-800 translate-x-10 translate-y-4 rounded-lg shadow-md"
                style={{
                  backgroundImage: "url(./images/card-2.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
              <div
                className="w-[350px] h-[120px] bg-sky-300 translate-x-28 translate-y-56 rounded-lg shadow-md"
                style={{
                  backgroundImage: "url(./images/card-2-top.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            {/* Text Section */}
            <div className="h-[500px] flex flex-col justify-center items-end">
              <p className="text-lg text-gray-500">Security Features</p>
              <h2 className="my-2 text-gray-700 font-semibold text-2xl">
                Protect Skill Test Integrity
              </h2>
              <p className="text-gray-800 text-sm my-3 text-end">
                Ensure a secure and fair skill test environment with advanced
                anti-cheating measures. Utilize browser lockdown and AI-driven
                proctoring to prevent unauthorized access, protecting the
                integrity of your skill tests and delivering accurate,
                trustworthy results.
              </p>
              <button
                onClick={handleTakeTest}
                className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
              >
                Explore more
                <FaArrowRightLong
                  size={20}
                  className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                />
              </button>
            </div>
          </div>

          {/* CARD 3 */}
          <div className="flex flex-row h-auto w-full max-w-[1140px] items-center mt-16 home-animation">
            {/* photo */}
            <div className="h-[520px] w-[420px]">
              <div className="w-[500px] h-[400px] absolute bg-teal-100 rounded-lg shadow-md"></div>
              <div
                className="w-[480px] h-[380px] bg-teal-300 translate-x-8 translate-y-8 rounded-lg shadow-md"
                style={{
                  backgroundImage: "url(./images/card-3.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            {/* Text Section */}
            <div className="h-auto flex flex-col items-start ml-44 -mt-14">
              <p className="text-lg text-gray-500">Reporting & Analytics</p>
              <h2 className="my-2 text-gray-700 font-semibold text-2xl">
                Optimize Your Hiring Decisions
              </h2>
              <p className="text-gray-800 text-sm my-3 text-start">
                Gain deep insights into your hiring process with customizable
                dashboards and detailed analytics. Track key performance
                indicators like pass rates, time-to-hire, and candidate quality,
                empowering you to make data-driven decisions and continuously
                enhance recruitment outcomes.
              </p>
              <button
                onClick={handleTakeTest}
                className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
              >
                Explore more
                <FaArrowRightLong
                  size={20}
                  className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                />
              </button>
            </div>
          </div>

          {/* CARD 4 */}
          <div className="flex flex-row-reverse h-auto w-full max-w-[1140px] items-center mt-8 home-animation">
            {/* photo */}
            <div className="h-[600px] w-[420px] mr-16">
              <div
                className="w-[460px] h-[520px] absolute bg-sky-100 rounded-lg shadow-lg"
                style={{
                  backgroundImage: "url(./images/faq/faq-bg.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
              <div
                className="w-[400px] h-[420px] bg-sky-300 -translate-x-40 translate-y-12 rounded-lg shadow-lg"
                style={{
                  backgroundImage: `url(${sidesData[selectedIndex].img})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            {/* Text Section */}
            <div className="h-auto flex flex-col items-start mr-56">
              {sidesData.map((item, index) => (
                <div
                  key={index}
                  className={`p-4 cursor-pointer ${
                    selectedIndex === index ? "bg-gray-200 rounded-md" : ""
                  }`}
                  onClick={() => setSelectedIndex(index)}
                >
                  <h3 className="text-gray-700 font-semibold border-gray-700">
                    {item.questionf}
                  </h3>
                  {selectedIndex === index && (
                    <p className="text-gray-600 text-[11px] mt-2">
                      {item.deatils}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
