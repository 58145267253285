import { useSelector, useDispatch } from 'react-redux';
import { setSelectedSkill } from '../Redux/selectSkill/selectSkillSlice.js';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import Lottie from "lottie-react";
import { Button } from "flowbite-react";
import java from "../assets/java.json"
import python from "../assets/python.json"
import c from "../assets/c.json"
import dataAnalyst from '../assets/dataAnalyst.json';
import businessAnalyst from '../assets/businessAnalyst.json';
import businessIntelligence from '../assets/businessIntelligence.json'
import financialAnalyst from '../assets/financialAnalyst.json';
import uiux from '../assets/uiux.json';
import cyberSecurity from '../assets/cybersecurity.json'
import Machanical from '../assets/mechanical.json'
import JavaBackend from '../assets/java-backend.json'
import FullStack from '../assets/fullStackDeveloper.json'
import Electrical from '../assets/electrical.json'
import { FaArrowRightLong } from 'react-icons/fa6';
import { Helmet } from 'react-helmet-async';


export default function SelectSkill() {


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.user);

    const handleInstruction = () => {
        navigate('/skillTestInstruction')
    }


    const handleJavaDev = () => {
        dispatch(setSelectedSkill('java'))
        navigate('/testOptions?language=java')

    }

    const handleDataAnalyst = () => {
        dispatch(setSelectedSkill('dataanalyst'))
        navigate('/testOptions?language=dataanalyst')
    }

    const handleBusinessAnalyst = () => {
        dispatch(setSelectedSkill('businessAnalyst'))
        navigate('/testOptions?language=businessAnalyst')
    }

    const handleBusinessIntelligence = () => {
        dispatch(setSelectedSkill('businessIntelligence'))
        navigate('/testOptions?language=businessIntelligence')
    }

    const handleFinancialAnalyst = () => {
        dispatch(setSelectedSkill('financialAnalyst'))
        navigate('/testOptions?language=financialAnalyst')
    }

    const handleUiUx = () => {
        dispatch(setSelectedSkill('uiux'))
        navigate('/testOptions?language=uiux')
    }
    
    const handleCyberSecurity = () => {
        dispatch(setSelectedSkill('cyberSecurity'))
        navigate('/testOptions?language=cyberSecurity')
    }

    
    const handleMechanicalEngineer = () => {
        dispatch(setSelectedSkill('mechanicalEngineer'))
        navigate('/testOptions?language=mechanicalEngineer')
    }


    const handleJavaBackend = () => {
        dispatch(setSelectedSkill('javaBackend'))
        navigate('/testOptions?language=javaBackend')
    }


    const handleElectricalEngineer = () => {
        dispatch(setSelectedSkill('electricalEngineer'))
        navigate('/testOptions?language=electricalEngineer')
    }


    const handleFullStcakDeveloper = () => {
        dispatch(setSelectedSkill('fullStackDeveloper'))
        navigate('/testOptions?language=fullStackDeveloper')
    }


    //------------------ Handle Coming Soon --------------------
    const handleComingSoon = () => {
        navigate('/comingSoon')
    }
    return (
        <>
        <Helmet>
                <title>Skill assessment tools for hire or get hired</title>
                <meta
                  name="description"
                  content="Find free online skill tests to simplify hiring and showcase expertise. Perfect for coding skill tests or general assessments. Recruit top talent or stand out as a candidate effortlessly!"
                />
              </Helmet>
              
        <div className='w-screen flex justify-center'>

            <div className='flex justify-center flex-wrap items-center gap-10 mt-6 width-container'>
                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={java} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">JAVA Developer</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Basic JAVA</p>
                    <button
                        onClick={handleJavaDev}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={dataAnalyst} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Data Analyst</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Power BI, Python, Excel, SQL</p>
                    <button
                        onClick={handleDataAnalyst}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow  hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={businessAnalyst} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Business Analyst</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Power BI, Tableau, Advance Excel, SQL, Statistical Analytics</p>
                    <button
                        onClick={handleBusinessAnalyst}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>


                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={businessIntelligence} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Business Intelligence</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Power BI, Tableau, Advance Excel, SQL, Statistical Analytics</p>
                    <button
                        onClick={handleBusinessIntelligence}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={financialAnalyst} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Financial Analyst</h5>
                    </h1>
                    <p className="mb-3 text-[10px] font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Excel, Power BI, SQL, Business Studies, Quant, Fundamental and Technical Analysis, Stock Market, Economics</p>
                    <button
                        onClick={handleFinancialAnalyst}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>


                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={uiux} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">UI/UX</h5>
                    </h1>
                    <p className="mb-3 text-[10px] font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Ui/Ux, HTML</p>
                    <button
                        onClick={handleUiUx}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>


                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={cyberSecurity} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Cyber Security</h5>
                    </h1>
                    <p className="mb-3 text-[10px] font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Cyber Security</p>
                    <button
                        onClick={handleCyberSecurity}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>


                
                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={Machanical} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Mechanical Engineer</h5>
                    </h1>
                    <p className="mb-3 text-[10px] font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Mechanical Engineer</p>
                    <button
                        onClick={handleMechanicalEngineer}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>


                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={Electrical} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Electrical Engineer</h5>
                    </h1>
                    <p className="mb-3 text-[10px] font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Electrical Engineer</p>
                    <button
                        onClick={handleElectricalEngineer}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>


                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={JavaBackend} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Backend Using Java</h5>
                    </h1>
                    <p className="mb-3 text-[10px] font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Java Backend</p>
                    <button
                        onClick={handleJavaBackend}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>


                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={FullStack} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Full Stack Developer</h5>
                    </h1>
                    <p className="mb-3 text-[10px] font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Programming Languages, Backend, Frontend, Database, Cloud Platforms, Version Control</p>
                    <button
                        onClick={handleFullStcakDeveloper}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>



                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={python}></Lottie></div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">PYTHON Developer</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:</b>Python</p>
                    <button
                        onClick={handleComingSoon}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={c}></Lottie></div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">C++ Developer</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:</b>C++</p>
                    <button
                        onClick={handleComingSoon}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>
            </div>
        </div>
        </>
    )
}



